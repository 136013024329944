import DropDownMenu_SeparateLinesMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/SeparateLinesMenuButton/SeparateLinesMenuButtonSkin.skin';
import DropDownMenu_SeparateLinesMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_SeparateLinesMenuButtonSkin = {
  component: DropDownMenu_SeparateLinesMenuButtonSkinComponent,
  controller: DropDownMenu_SeparateLinesMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_SeparateLinesMenuButtonSkin']: DropDownMenu_SeparateLinesMenuButtonSkin
};

